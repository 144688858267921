import { CardRowProps } from '@hpx-core-experiences/react-my-account-commons/dist/components/Card/CardWrapper/CardRow';
import {
  Card,
  ROW_TYPE,
  useReadOnlyDataStreamer
} from '@hpx-core-experiences/react-my-account-commons/dist/index';
import React, { Dispatch, useState, SetStateAction, useCallback } from 'react';
import ErrorBoundary from 'src/App/ErrorBoundary';
import { useDependencyManagerContext } from 'src/contexts/dependencyManager';
import { PersonalDetailsError } from 'src/utils/analytics';
import { getDetails } from './utils/detailsConfig';
import getFieldsToFetch from './utils/getFieldsToFetch';
import Loader from '../Loader';
import { Value, ValueContent } from './styles';
import { FeatureFlagProps } from 'src/App';

export interface PersonalDetailsType {
  firstName?: string;
  lastName?: string;
  email?: string;
  phone?: string;
  preferredLanguage?: string;
  userCountry?: string;
}

export interface Flags {
  isHPIDNameEnabled: boolean;
  isHPIDEmailEnabled: boolean;
  isHPIDPhoneEnabled: boolean;
  isHPIDPreferredLanguageEnabled: boolean;
  isHPIDCountryRegionEnabled: boolean;
  isShowPreferredLanguageEnabled: boolean;
}

const PersonalDetails = (featureFlags: FeatureFlagProps) => {
  const { translate } = useDependencyManagerContext();
  const [hpIdUser, setHpIdUser] = useState<PersonalDetailsType>({
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    preferredLanguage: '',
    userCountry: ''
  });

  const getFieldsToFetchWithFlags = useCallback(
    (setPersonalDetails: Dispatch<SetStateAction<PersonalDetailsType>>) =>
      getFieldsToFetch(setPersonalDetails, featureFlags),
    [featureFlags]
  );

  const { error, loading } = useReadOnlyDataStreamer(
    setHpIdUser,
    getFieldsToFetchWithFlags
  );

  if (error) {
    return (
      <Card
        title={{
          content: translate(
            'profile.personalDetails',
            'Personal details'
          ) as string
        }}
        rowsColorChangeEnabled={false}
        data-testid="personal-details-error"
      >
        <ErrorBoundary
          analyticsEvent={PersonalDetailsError('HP ID Widgets error')}
        />
      </Card>
    );
  }

  if (loading) {
    return <Loader />;
  }

  const details = getDetails(translate, featureFlags, hpIdUser);

  return (
    <Card
      title={{
        content: translate(
          'profile.personalDetails',
          'Personal details'
        ) as string,
        type: ROW_TYPE.INTERNAL
      }}
      rows={details.map((detail, index) => ({
        index: index,
        type: detail.chevron ? ROW_TYPE.INTERNAL : ROW_TYPE.EXTERNAL,
        analyticsEvent: detail.analyticsEvent,
        link: { url: detail.url, pathname: detail.pathName },
        content: [
          { value: detail.label },
          {
            value: (
              <Value>
                <ValueContent data-testid={detail.testId}>
                  {detail.value}
                </ValueContent>
              </Value>
            ),
            fsDataComponent: 'UserDataField'
          }
        ] as CardRowProps['content']
      }))}
      data-testid="personal-details"
    />
  );
};

export default PersonalDetails;
