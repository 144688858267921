export enum FeatureFlagEnum {
  profileHpIdCountryRegion = 'profile-hp-id-country-region',
  profileHpIdEmail = 'profile-hp-id-email',
  profileHpIdName = 'profile-hp-id-name',
  profileHpIdPhone = 'profile-hp-id-phone',
  profileHpIdPreferredLanguage = 'profile-hp-id-preferred-language',
  profileListPersonalDetails = 'profile-list-personal-details',
  originalFeedbackExperience = 'original-feedback-experience',
  localeFiltering = 'locale-filtering',
  showPreferredLanguage = 'show-preferred-language'
}

export enum FormTypes {
  Country = 'country',
  Email = 'email',
  Language = 'language',
  Name = 'name',
  Phone = 'phone'
}
