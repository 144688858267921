import {
  LookingForSomethingElse,
  SectionHeader
} from '@hpx-core-experiences/react-my-account-commons/dist/index';
import React, { useEffect } from 'react';
import HPStoreNewsletter from 'src/components/HPStoreNewsletter';
import ContentWrapper from './ContentWrapper';
import { useDependencyManagerContext } from 'src/contexts/dependencyManager';
import {
  ProfileScreenDisplayed,
  publishEvent,
  screenName,
  screenPath
} from 'src/utils/analytics';
import { Content, Header } from './styles';
import { FeatureFlagProps } from 'src/App';

const Profile = (featureFlags: FeatureFlagProps) => {
  const { translate } = useDependencyManagerContext();

  useEffect(() => {
    publishEvent(ProfileScreenDisplayed);
  }, []);

  return (
    <>
      <Header data-testid="header">
        <SectionHeader
          title={translate('menu.profile', 'Profile')}
          subtitle={translate(
            'profile.managePersonalData',
            'Manage your personal details and addresses'
          )}
        />
      </Header>
      <Content>
        <ContentWrapper {...featureFlags} />
        <HPStoreNewsletter />
      </Content>
      {featureFlags.originalFeedbackExperience && (
        <LookingForSomethingElse
          analytics={{
            screenName: screenName,
            screenPath: screenPath
          }}
          translate={translate}
        />
      )}
    </>
  );
};

export default Profile;
