import { URLS } from 'src/utils/urls';
import { FormTypes } from 'src/utils/enums';
import {
  ManagePersonalDetailsCountryRegionButtonClicked,
  ManagePersonalDetailsCountryRegionHyperlinkClicked,
  ManagePersonalDetailsEmailHyperlinkClicked,
  ManagePersonalDetailsEmailButtonClicked,
  ManagePersonalDetailsLanguageButtonClicked,
  ManagePersonalDetailsLanguageHyperlinkClicked,
  ManagePersonalDetailsNameButtonClicked,
  ManagePersonalDetailsNameHyperlinkClicked,
  ManagePersonalDetailsPhoneHyperlinkClicked,
  ManagePersonalDetailsPhoneButtonClicked,
  AnalyticsEventPropTypes
} from 'src/utils/analytics';
import { formatPhoneNumber } from '@hpx-core-experiences/react-my-account-commons/dist/index';
import { FeatureFlagProps } from 'src/App';

interface HpIdUser {
  firstName?: string;
  lastName?: string;
  email?: string;
  phone?: string;
  preferredLanguage?: string;
  userCountry?: string;
}

interface DetailConfig {
  label: string;
  value: string;
  chevron?: boolean;
  testId: string;
  analyticsEvent: AnalyticsEventPropTypes;
  url: string;
  pathName: string;
}

/**
 * Generates a list of detail configurations for personal details.
 *
 * This function is used in the `PersonalDetails` component to create a detailed configuration
 * for each personal detail of the user. The generated configurations are then used to render
 * rows in a `Card` component, displaying the user's personal details such as name, email, phone,
 * preferred language, and country/region.
 *
 * @param translate - Function to translate keys to localized strings.
 * @param featureFlags - Optional feature flags to control the behavior of the details.
 * @param hpIdUser - Optional HP Id user details.
 * @returns An array of DetailConfig objects containing the configuration for each personal detail.
 */
export const getDetails = (
  translate: (key: string, defaultValue: string) => string,
  featureFlags: FeatureFlagProps,
  hpIdUser: HpIdUser
): DetailConfig[] => {
  const getPreferredLanguage = () => {
    const locale = hpIdUser?.preferredLanguage;
    return translate(`languages.${locale}`, locale);
  };

  const getCountryRegion = () => {
    const parsedLocale = hpIdUser?.userCountry?.toUpperCase();
    return translate(`countryNames.${parsedLocale}`, parsedLocale);
  };

  const noPhoneMessage = translate('profile.noPhone', 'No phone');

  const {
    profileHpIdName: isHpIDNameEnabled,
    profileHpIdEmail: isHpIDEmailEnabled,
    profileHpIdPhone: isHpIDPhoneEnabled,
    profileHpIdPreferredLanguage: isHpIDPreferredLanguageEnabled,
    profileHpIdCountryRegion: isHpIDCountryRegionEnabled,
    showPreferredLanguage
  } = featureFlags;

  return [
    {
      label: translate('profile.name', 'Name'),
      value: `${hpIdUser.firstName} ${hpIdUser.lastName}`,
      chevron: isHpIDNameEnabled,
      testId: 'name',
      analyticsEvent: isHpIDNameEnabled
        ? ManagePersonalDetailsNameButtonClicked
        : ManagePersonalDetailsNameHyperlinkClicked,
      url: URLS.HpProfile,
      pathName: `/profile/${FormTypes.Name}`
    },
    {
      label: translate('profile.email', 'Email'),
      value: hpIdUser.email || translate('profile.noEmail', 'No email'),
      chevron: isHpIDEmailEnabled,
      testId: 'email',
      analyticsEvent: isHpIDEmailEnabled
        ? ManagePersonalDetailsEmailButtonClicked
        : ManagePersonalDetailsEmailHyperlinkClicked,
      url: URLS.HpProfile,
      pathName: `/profile/${FormTypes.Email}`
    },
    {
      label: translate('profile.phone', 'Phone'),
      value: formatPhoneNumber(
        hpIdUser.phone ||
          `{
            "national_number": "",
            "country_code": "",
            "verified": "false"
          }`,
        noPhoneMessage
      ),
      chevron: isHpIDPhoneEnabled,
      testId: 'phone',
      analyticsEvent: isHpIDPhoneEnabled
        ? ManagePersonalDetailsPhoneButtonClicked
        : ManagePersonalDetailsPhoneHyperlinkClicked,
      url: URLS.HpProfile,
      pathName: `/profile/${FormTypes.Phone}`
    },
    showPreferredLanguage && {
      label: translate('profile.preferredLanguage', 'Preferred language'),
      value: getPreferredLanguage(),
      chevron: isHpIDPreferredLanguageEnabled,
      testId: 'language',
      analyticsEvent: isHpIDPreferredLanguageEnabled
        ? ManagePersonalDetailsLanguageButtonClicked
        : ManagePersonalDetailsLanguageHyperlinkClicked,
      url: URLS.HpProfile,
      pathName: `/profile/${FormTypes.Language}`
    },
    {
      label: translate('profile.countryRegion', 'Country/Region'),
      value: getCountryRegion(),
      chevron: isHpIDCountryRegionEnabled,
      testId: 'country-region',
      analyticsEvent: isHpIDCountryRegionEnabled
        ? ManagePersonalDetailsCountryRegionButtonClicked
        : ManagePersonalDetailsCountryRegionHyperlinkClicked,
      url: URLS.HpProfile,
      pathName: `/profile/${FormTypes.Country}`
    }
  ].filter(Boolean);
};
