import tokens from '@veneer/tokens';
import styled from 'styled-components';
import { MainTheme } from '@hpx-core-experiences/react-my-account-commons/dist';

export const AddressSectionContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${tokens.layout.size6};

  @media (${MainTheme.mediaQueries.maxWidthMobile}) {
    gap: ${tokens.layout.size4};
  }
`;

export const AddressLink = styled.div`
  font-size: ${tokens.typography.size1};
  padding-bottom: ${tokens.layout.size3};
`;
