import React from 'react';
import { useDependencyManagerContext } from 'src/contexts/dependencyManager';
import { FormTypes } from 'src/utils/enums';
import LoadingScreen from 'src/components/LoadingScreen';
import PersonalDetailsEditor from 'src/pages/PersonalDetailsEditor';
import Profile from 'src/pages/Profile';
import { FeatureFlagProps } from 'src/App';

const SUB_ROUTES = Object.values(FormTypes);

const Router = (featureFlagValue: FeatureFlagProps) => {
  const { northboundAPIs } = useDependencyManagerContext();

  const pathName = northboundAPIs.v1.navigation.location.pathname || '';

  const { showPreferredLanguage, isLoading } = featureFlagValue;

  const filteredSubRoutes = SUB_ROUTES.filter(
    (route) => showPreferredLanguage || route !== FormTypes.Language
  );

  const subRouteExists = filteredSubRoutes.includes(
    pathName.replace('/profile/', '').replace(/\//g, '') as FormTypes
  );

  const shouldRedirect =
    !isLoading && pathName.includes('/profile/') && !subRouteExists;

  if (isLoading || shouldRedirect) {
    if (shouldRedirect) {
      northboundAPIs.v1.navigation.redirect('/profile');
    }
    return <LoadingScreen />;
  }

  if (subRouteExists) {
    return <PersonalDetailsEditor {...featureFlagValue} />;
  }

  return <Profile {...featureFlagValue} />;
};

export default Router;
