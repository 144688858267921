import tokens from '@veneer/tokens';
import { MainTheme } from '@hpx-core-experiences/react-my-account-commons/dist';
import styled from 'styled-components';

export const Content = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: ${tokens.layout.size6};

  &:last-child {
    margin-bottom: ${tokens.layout.size2};
  }

  @media (${MainTheme.mediaQueries.maxWidthMobile}) {
    gap: ${tokens.layout.size4};
  }
`;
