import {
  GenericFormContainer,
  Loading,
  WIDGETS_TYPE,
  setHpIdCountry,
  setupIntervalWithEventListener
} from '@hpx-core-experiences/react-my-account-commons/dist/index';
import React, { useCallback, useEffect, useMemo } from 'react';
import { useDependencyManagerContext } from 'src/contexts/dependencyManager';
import {
  ProfileDetailsScreenDisplayed,
  publishEvent
} from 'src/utils/analytics';
import { FormTypes } from 'src/utils/enums';
import { Center } from 'src/App/styles';
import { FeatureFlagProps } from 'src/App';

const PersonalDetailsEditor = (featureFlags: FeatureFlagProps) => {
  const {
    isLoading,
    profileHpIdName,
    profileHpIdEmail,
    profileHpIdCountryRegion,
    profileHpIdPreferredLanguage,
    profileHpIdPhone,
    localeFiltering,
    saveCountryInfoHpIdClientOsStore
  } = featureFlags;

  const { translate, northboundAPIs } = useDependencyManagerContext();
  const navigation = northboundAPIs?.v1?.navigation;
  const country = northboundAPIs?.v1?.localization.country;
  const language = northboundAPIs?.v1?.localization.language;

  const path =
    (navigation.location.pathname.split('/').pop() as FormTypes) || '';

  const pathToRedirect = useMemo(
    () => `/${country}/${language}/profile`,
    [country, language]
  );

  const handleRedirect = useCallback(() => {
    navigation?.push(pathToRedirect);
  }, [navigation, pathToRedirect]);

  const formTypes = useMemo(() => {
    const types = [] as FormTypes[];
    profileHpIdName && types.push(FormTypes.Name);
    profileHpIdEmail && types.push(FormTypes.Email);
    profileHpIdCountryRegion && types.push(FormTypes.Country);
    profileHpIdPreferredLanguage && types.push(FormTypes.Language);
    profileHpIdPhone && types.push(FormTypes.Phone);
    return types;
  }, [
    profileHpIdName,
    profileHpIdEmail,
    profileHpIdCountryRegion,
    profileHpIdPreferredLanguage,
    profileHpIdPhone
  ]);

  useEffect(() => {
    if (!isLoading) {
      if (path !== null && formTypes.includes(path as FormTypes)) {
        publishEvent({
          ...ProfileDetailsScreenDisplayed,
          actionAuxParams: `type=${path}`
        });
        return;
      }
      handleRedirect();
    }
  }, [formTypes, handleRedirect, path, isLoading]);

  useEffect(() => {
    if (saveCountryInfoHpIdClientOsStore) {
      const cleanup = setupIntervalWithEventListener(
        'multi-control',
        'commit',
        async () => {
          await setHpIdCountry();
        }
      );
      return cleanup;
    }
  }, [saveCountryInfoHpIdClientOsStore]);

  const widgets: WIDGETS_TYPE[] = useMemo(() => {
    switch (path) {
      case FormTypes.Name:
        return [
          WIDGETS_TYPE.PROFILE_FIELD_FIRST_NAME,
          WIDGETS_TYPE.PROFILE_FIELD_LAST_NAME
        ];
      case FormTypes.Language:
        return [WIDGETS_TYPE.PROFILE_SELECT_LANGUAGE];
      case FormTypes.Country:
        return [WIDGETS_TYPE.PROFILE_SELECT_COUNTRY];
      case FormTypes.Email:
        return [WIDGETS_TYPE.CHANGE_EMAIL];
      case FormTypes.Phone:
        return [WIDGETS_TYPE.PHONE];
      default:
        return [];
    }
  }, [path]);

  const showButtons = useMemo(() => {
    return path !== FormTypes.Email && path !== FormTypes.Phone;
  }, [path]);

  if (isLoading) {
    return (
      <Center>
        <Loading testid="loading-personal-details-editor" />
      </Center>
    );
  }

  return (
    <div>
      <GenericFormContainer
        title={translate('profile.personalDetails', 'Personal details')}
        widgets={widgets}
        language={`${language}_${country.toUpperCase()}`}
        showButtons={showButtons}
        labels={{
          cancel: translate('common.cancel', 'Cancel'),
          save: translate('common.save', 'Save')
        }}
        onClickArrowLeft={handleRedirect}
        allowedLocalesEnabled={localeFiltering}
      />
    </div>
  );
};

export default PersonalDetailsEditor;
