import tokens from '@veneer/tokens';
import styled from 'styled-components';
import { MainTheme } from '@hpx-core-experiences/react-my-account-commons/dist';

export const Row = styled.div`
  display: grid;
  grid-template-columns: 200px 8fr 1fr;
  gap: ${tokens.layout.size2};

  @media screen and (${MainTheme.mediaQueries.maxWidthMobile}) {
    grid-template-columns: 1fr;
  }
`;

export const Label = styled.div`
  display: flex;
  align-items: center;
  color: ${tokens.color.gray7};
`;

export const Value = styled.div`
  display: flex;
  overflow: hidden;
  text-overflow: ellipsis;

  && {
    @media screen and (${MainTheme.mediaQueries.maxWidthMobile}) {
      width: 271px;
    }
  }
`;

export const ValueContent = styled.div`
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;
