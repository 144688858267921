import React from 'react';
import { Center } from 'src/App/styles';
import { Loading } from '@hpx-core-experiences/react-my-account-commons/dist';

const LoadingScreen = () => (
  <Center>
    <Loading />
  </Center>
);

export default LoadingScreen;
