import { Card, IconGhostWaveWall } from '@veneer/core';
import tokens from '@veneer/tokens';
import { MainTheme } from '@hpx-core-experiences/react-my-account-commons/dist';
import styled from 'styled-components';

export const AddressSectionContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${tokens.layout.size6};

  @media (${MainTheme.mediaQueries.maxWidthMobile}) {
    gap: ${tokens.layout.size4};
  }
`;

export const AddressLink = styled.div`
  font-size: ${tokens.typography.size1};
  padding-top: ${tokens.layout.size3};
  padding-bottom: ${tokens.layout.size3};
`;

export const IconGhost = styled(IconGhostWaveWall)`
  && {
    color: ${tokens.color.gray9};
  }
`;

export const NoAddresses = styled(Card)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  gap: ${tokens.layout.size8};
`;

export const NoAddressesText = styled.body`
  && {
    color: ${tokens.color.gray9};
  }
`;
