import React, { useMemo } from 'react';
import { FeatureFlagProps } from 'src/App';
import Addresses from 'src/components/Addresses';
import Loader from 'src/components/Loader';
import OldAddresses from 'src/components/OldAddresses';
import OldPersonalDetails from 'src/components/OldPersonalDetails';
import PersonalDetails from 'src/components/PersonalDetails';

const ContentWrapper: React.FC<FeatureFlagProps> = (featureFlags) => {
  const { profileListPersonalDetails, isLoading } = featureFlags;

  const PersonalDetailsWrapper = useMemo(() => {
    if (isLoading) {
      return <Loader />;
    }

    return profileListPersonalDetails ? (
      <PersonalDetails {...featureFlags} />
    ) : (
      <OldPersonalDetails />
    );
  }, [featureFlags, profileListPersonalDetails, isLoading]);

  const AddressesWrapper = useMemo(() => {
    if (isLoading) {
      return <Loader />;
    }

    return profileListPersonalDetails ? <Addresses /> : <OldAddresses />;
  }, [profileListPersonalDetails, isLoading]);

  return (
    <>
      {PersonalDetailsWrapper}
      {AddressesWrapper}
    </>
  );
};

export default ContentWrapper;
