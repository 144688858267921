import {
  ContainerMFE,
  useGetFeatureFlags,
  FeatureFlagKeyValues
} from '@hpx-core-experiences/react-my-account-commons/dist';
import React, { useMemo } from 'react';
import {
  DependencyManagerProvider,
  useDependencyManagerContext
} from 'src/contexts/dependencyManager';
import Router from 'src/router';
import '../styles/global.scss';
import * as T from './types';

export interface FeatureFlagProps {
  isLoading: boolean;
  profileHpIdCountryRegion: boolean;
  profileHpIdEmail: boolean;
  profileHpIdName: boolean;
  profileHpIdPhone: boolean;
  profileHpIdPreferredLanguage: boolean;
  profileListPersonalDetails: boolean;
  originalFeedbackExperience: boolean;
  localeFiltering: boolean;
  saveCountryInfoHpIdClientOsStore: boolean;
  showPreferredLanguage: boolean;
}

const AppComponent: React.FC<T.AppPropsType> = () => {
  const { northboundAPIs } = useDependencyManagerContext();

  const client = useMemo(
    () => northboundAPIs.v1.graphql.getClient(),
    [northboundAPIs.v1.graphql]
  );
  const GraphQLProvider = useMemo(
    () => northboundAPIs.v1.graphql.reactTools.createGraphQLProvider(React),
    [northboundAPIs.v1.graphql.reactTools]
  );

  const featureFlagClient = useMemo(
    () => northboundAPIs?.v1?.featureFlags?.getClient,
    [northboundAPIs?.v1?.featureFlags?.getClient]
  );

  const featureFlagProps = useGetFeatureFlags({
    getClient: featureFlagClient,
    keys: [
      FeatureFlagKeyValues.profileHpIdCountryRegion,
      FeatureFlagKeyValues.profileHpIdEmail,
      FeatureFlagKeyValues.profileHpIdName,
      FeatureFlagKeyValues.profileHpIdPhone,
      FeatureFlagKeyValues.profileHpIdPreferredLanguage,
      FeatureFlagKeyValues.profileListPersonalDetails,
      FeatureFlagKeyValues.originalFeedbackExperience,
      FeatureFlagKeyValues.localeFiltering,
      FeatureFlagKeyValues.saveCountryInfoHpIdClientOsStore,
      FeatureFlagKeyValues.showPreferredLanguage
    ]
  });

  return (
    <GraphQLProvider client={client}>
      <ContainerMFE data-testid="profile-page">
        <Router {...featureFlagProps} />
      </ContainerMFE>
    </GraphQLProvider>
  );
};

const App: React.FC<T.AppPropsType> = () => {
  return (
    <DependencyManagerProvider>
      <AppComponent />
    </DependencyManagerProvider>
  );
};

export default App;
